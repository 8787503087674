import orderService from '@/services/order.service';

export const orderModule = {
  namespaced: true,
  state: {
    orders: {
      links: {},
      meta: {},
      results: []
    },
    order: {
      results: {
        empresa: {},
        fornecedor: {},
      },
      products: {
        links: {},
        meta: {},
        results: []
      }
    }
  },
  mutations: {
    fill_order_list(state, orders) {
      state.orders = orders;
    },
    fill_order_view(state, order) {
      Object.assign(state.order, {
        ...order,
        products: {
          links: {},
          meta: {},
          results: []
        }
      });
    },
    fill_order_products(state, products) {
      state.order.products =  products;
    },
  },
  actions: {
    async list({ commit }, url) {
      const response = await orderService.list(url);
      return commit('fill_order_list', response.data);
    },
    async view({ commit }, id) {
      const response = await orderService.view(id);
      return commit('fill_order_view', response.data);
    },
    async listProducts({ commit }, query) {
      const response = await orderService.listProducts(query);
      return commit('fill_order_products', response.data);
    },
  },
  getters: {
    orders: (state) => state.orders,
    order: (state) => state.order
  }
};
