import stokService from '@/services/stok.service';


export const stockModule = {
  namespaced: true,
  state: {
    stock: {
      links: {},
      meta: {},
      results: []
    }
  },
  mutations: {
    fill_stock(state, stock) {
      state.stock = stock;
    }
  },
  actions: {
    async list({ commit }, queryString) {
      const response = await stokService.list(queryString);
      return response.code === 200 ? commit('fill_stock', response.data) : null;
    }
  },
  getters: {
    stock: (state) => state.stock
  }
};
