import equipmentService from '@/services/equipment.service';
import LocaleService from '@/services/locale.service';

export const equipmentModule = {
  namespaced: true,
  state: {
    company: {},
    equipment: {
      links: {},
      meta: {},
      results: [],
    },
    url: undefined,
    queryString: undefined
  },
  mutations: {
    FILL_EQUIPMENT(state, equipment) {
      state.equipment = equipment;
    },
    FILL_COMPANY(state, company) {
      state.company = company;
    },
    FILL_APP(state, app) {
      state.app = app;
    },
    FILL_COUNTRY(state, country) {
      state.country = country;
    },
  },
  actions: {
    async getEquipment({ commit }) {
      let columns = 'columns=cod_emp,chave_host_md5,tipo_hosts,cod_hosts,desc_hosts,mac_address_hosts,ip_hosts,subnet_mask_hosts,hostname_hosts';
      let url = this.state.equipmentModule.url;
      let query = this.state.equipmentModule.queryString || '';
      const equipment = await equipmentService.getEquipment(
        `${url}&${columns}&${query}`
      );
      equipment.code === 200 ? commit('FILL_EQUIPMENT', equipment.data) : null;
    },
    async getCompany({ commit }) {
      const equipment = await equipmentService.getCompany();
      equipment.code === 200 ? commit('FILL_COMPANY', equipment.data) : null;
    },
    async deleteRedirectSat({ commit }, id) {
      await equipmentService.deleteRedirectSat(id).catch((error) => {
        let message = `Não foi possível excluír redirecionamento de equipamento SAT!`;
        Toast.fire('Atenção', message, 'error');
      });
    },
  },
  getters: {
    url: (state) => state.url,
    equipment: (state) => state.equipment,
    company: (state) => state.company,
  },
};
