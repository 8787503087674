import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './modules/auth.module';
import { home } from './modules/home.module';
import { newProduct } from './modules/newProduct.module';
import { product } from './modules/product.module';
import { user } from './modules/user.module';
import { catalog } from './modules/catalog.module';
import { productObservation } from './modules/productObservation.module';
import { tablePrice } from './modules/tablePrice.module';
import { publish } from './modules/publish.module';
import { productGroup } from './modules/productGroup.module';
import { dashboard } from './modules/dashboard.module';
import { taxRule } from './modules/taxRule.module';
import { discounts } from './modules/discounts.module';
import { reasons } from './modules/reasonsReversal.module';
import { paymentMethods } from './modules/paymentMethods.module';
import { equipmentModule } from './modules/equipment.module';
import { importModule } from './modules/import.module';
import { chat } from './modules/chat.module';
import { entryCategories } from './modules/entryCategory.module';
import { fractionTable } from './modules/fractionTable.module';
import { employees } from './modules/employees.module';
import { profile } from './modules/profile.module';
import { directPrint } from './modules/directPrint.module';
import { schedule } from './modules/schedule.module';
import { services } from './modules/services.module';
import { exchangeModule } from '@/store/modules/exchange.module';
import { clientsModule } from '@/store/modules/clients.module';
import { categoryIcmsModule } from '@/store/modules/categoryIcms.module';
import { categoryPisCofinsModule } from '@/store/modules/categoryPisCofins.module';
import { logaccessModule } from '@/store/modules/logaccess.module';
import { listModule } from '@/store/modules/list.module';
import { monitorNfeModule } from '@/store/modules/monitorNfe.module';
import { orderModule } from '@/store/modules/order.module';
import { stockModule } from '@/store/modules/stock.module';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    auth,
    home,
    newProduct,
    product,
    user,
    catalog,
    productObservation,
    tablePrice,
    productGroup,
    dashboard,
    taxRule,
    categoryIcmsModule,
    categoryPisCofinsModule,
    discounts,
    equipmentModule,
    importModule,
    exchangeModule,
    clientsModule,
    reasons,
    paymentMethods,
    chat,
    entryCategories,
    fractionTable,
    employees,
    profile,
    directPrint,
    schedule,
    services,
    publish,
    listModule,
    logaccessModule,
    monitorNfeModule,
    orderModule,
    stockModule
  }
});
export default store;
